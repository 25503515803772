
<div class="container" fxLayout="column" fxLayoutGap="100px" display="flex" fxFlex="flex-grow">
    <div fxLayout="row">
        <digitala-header></digitala-header>
    </div>
    <!-- <button id="add-todo" (click)="addTodo()">Add Todo</button> -->
    <div fxLayout="row" fxLayout.xs="column">
        <div class="list1">
            <digitala-book-list [books]="books1"></digitala-book-list>
        </div>
        <div class="list2">
            <digitala-book-list [books]="books2"></digitala-book-list>
        </div>
    </div>

    <div>
        <footer>Made with love by @digitala !!</footer>
    </div>
</div>


<!-- <span>
    <span>Photo by <a href="https://unsplash.com/@clemono2?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Clem Onojeghuo</a> on <a href="https://unsplash.com/s/photos/woodwork?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>
</span> -->