import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatGridListModule} from '@angular/material/grid-list';

import { HeaderComponent } from './header/header.component';
import { BookListComponent } from './book-list/book-list.component';

@NgModule({
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatGridListModule
          ],
  declarations: [HeaderComponent, BookListComponent],
  exports: [HeaderComponent, BookListComponent],
})
export class UiModule {}
