
<div>
    <mat-nav-list cols="2" rowHeight="1:1">
        <mat-list-item *ngFor="let b of books" class="book">
            <a href= {{b.url}} >
                
                <div mat-line><mat-icon mat-list-icon>description</mat-icon>{{ b.title }}</div>
            </a>
        </mat-list-item>
    </mat-nav-list>
</div>
