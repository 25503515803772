import { Component, OnInit, Input } from '@angular/core';
import { Todo } from '@digitala/api-interfaces';

@Component({
  selector: 'digitala-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() todos: Todo[];

  constructor() { }

  ngOnInit(): void {
  }

}
