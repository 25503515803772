import { Component, OnInit, Input } from '@angular/core';
import { Book } from '@digitala/api-interfaces';

import {MatListModule} from '@angular/material/list'; 
@Component({
  selector: 'digitala-book-list',
  templateUrl: './book-list.component.html',
  styleUrls: ['./book-list.component.scss']
})
export class BookListComponent implements OnInit {
  @Input() books: Book[];

  constructor() { }

  ngOnInit(): void {
  }

}
